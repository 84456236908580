.timelineCircle{
    z-index: 1;
    /* Rounded border */
    background-color: rgb(255, 255, 255);
    border-radius: 9999px;

    /* Size */
    height: 3.5rem;
    width:  3.5rem;
    margin-left: -1em;

}

.timelineLineItem{
    margin-bottom: 0px;
}

.timelineItemHeader{
    /* Center the content horizontally */
    align-items: center;
    display: flex;
}

.timelineCircle p{
    margin-top: auto;
  margin-bottom: auto;
  font-family: "Fredoka One", Helvetica, Arial, serif;
  font-weight: 400;
  font-style: normal;
  font-size: 1.25rem;
  text-align: center;
  vertical-align: middle;
  white-space: nowrap;
  line-height: 3.5rem;
  color:#4b228d;
}

.timelineItemTitle{
    /* Take available width */
    flex: 1;
    margin-left: 1em;
}
.timelineItemDescription{
    /* Make it align with the title */
    margin-left: 2.5em;
    margin-top: 0.5em;
    margin-bottom: 0.5em;
}



.blockHeaderSmall{
    margin-left: -3rem;
    padding-left: 3.5rem;
    padding-bottom: 2rem;
    margin-top: 1rem;
    margin-bottom: 0.5rem;
    
}

.blockHeaderSmall .timelineItemTitleText{
    font-size: 1.45rem;
    margin-top: -1rem;
    line-height: 1rem;
}

.blockHeaderSmall .timelineItemTitleCompany{
    font-family: "Roboto", Helvetica, Arial, serif;
    font-size: 1rem;
    margin-top: 0.25rem;
}

.timelineCircle .timelineMonth{
    font-family: "Roboto", Helvetica, Arial, serif;
    font-size: 0.75rem;
    margin-top: 1rem;
    margin-bottom: -0.75rem;
    line-height: 0;
}