@import url("https://fonts.googleapis.com/css?family=Roboto:400,700|Fira+Sans:700|Fredoka+One:400");
html,
body {
  background-color: #2c1451;
  width: 100%;
  height: 100%;
  min-height: 100%;
  color: #ffffff;
  box-sizing: border-box;
  padding: 0.25rem;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}
.container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

main {
  display: flex;
  flex: 1;
}

footer {
  grid-column: span 3;
  height: 2rem;
  background-color: rgba(75, 34, 141, 1);
  margin-top: 0.5rem;
  border-radius: 10rem;
}
.Bio {
  grid-column: span 3;
}

.contentblock {
  margin-top: 1rem;
  background-color: #6732bb;
  border-radius: 2rem 2rem 1rem 1rem;
  height: auto;
  width: auto;
  font-family: "Fredoka One", Helvetica, Arial, serif;
  font-weight: 400;
  font-style: normal;
  font-size: 1rem;
  text-align: left;
  line-height: 1rem;
}
.blockHeader {
  background-color: #4b228d;
  border-radius: 2rem;
  height: 2rem;
  width: 100%;
  padding: 1.5rem;
}
.blockHeader p {
  margin-top: auto;
  margin-bottom: auto;
  font-family: "Fredoka One", Helvetica, Arial, serif;
  font-weight: 400;
  font-style: normal;
  font-size: 2rem;
  text-align: left;
  vertical-align: middle;
  white-space: nowrap;
  line-height: 0rem;
}

.blockHeaderSmall {
  background-color: #4b228d;
  border-radius: 2rem;
  height: 2rem;
  width: 100%;
  padding: 1.5rem;
}
.blockHeaderSmall p {
  margin-top: auto;
  margin-bottom: auto;
  font-family: "Fredoka One", Helvetica, Arial, serif;
  font-weight: 400;
  font-style: normal;
  font-size: 1rem;
  text-align: left;
  vertical-align: middle;
  white-space: nowrap;
  line-height: 0rem;
}

.contentItem {
  margin: 1rem;
  padding-bottom: 1rem;
}

.contentColumn {
  flex: 1;
  /* Space between columns */
  margin: 0 8px;

  /* Layout each column */
  display: flex;
  flex-direction: column;
}

.contentColumnFullHeight {
  /* Take available height */
  flex: 1;
}

.blockTextLineBold {
  display: inline;
}
.blockTextLineDetails {
  display: inline;
  font-family: "Roboto", Helvetica, Arial, serif;
  color: #c29bff;
}

.blockTextDetailsWhite {
  display: inline;
  font-family: "Roboto", Helvetica, Arial, serif;
  color: #ffffff;
}

.ProjectContainer {
  /* Align sub-items to top */
  align-items: start;
  display: flex;
}

.ProjectImage {
  margin-right: 16px;
  /* Set the width for the media object */
  width: 315px;
}

.ProjectDescription {
  /* Take the remaining width */
  flex: 1;
}
