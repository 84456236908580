@import url(https://fonts.googleapis.com/css?family=Roboto:400,700|Fira+Sans:700|Fredoka+One:400);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

html,
body {
  background-color: #2c1451;
  width: 100%;
  height: 100%;
  min-height: 100%;
  color: #ffffff;
  box-sizing: border-box;
  padding: 0.25rem;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}
.container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

main {
  display: flex;
  flex: 1 1;
}

footer {
  grid-column: span 3;
  height: 2rem;
  background-color: rgba(75, 34, 141, 1);
  margin-top: 0.5rem;
  border-radius: 10rem;
}
.Bio {
  grid-column: span 3;
}

.contentblock {
  margin-top: 1rem;
  background-color: #6732bb;
  border-radius: 2rem 2rem 1rem 1rem;
  height: auto;
  width: auto;
  font-family: "Fredoka One", Helvetica, Arial, serif;
  font-weight: 400;
  font-style: normal;
  font-size: 1rem;
  text-align: left;
  line-height: 1rem;
}
.blockHeader {
  background-color: #4b228d;
  border-radius: 2rem;
  height: 2rem;
  width: 100%;
  padding: 1.5rem;
}
.blockHeader p {
  margin-top: auto;
  margin-bottom: auto;
  font-family: "Fredoka One", Helvetica, Arial, serif;
  font-weight: 400;
  font-style: normal;
  font-size: 2rem;
  text-align: left;
  vertical-align: middle;
  white-space: nowrap;
  line-height: 0rem;
}

.blockHeaderSmall {
  background-color: #4b228d;
  border-radius: 2rem;
  height: 2rem;
  width: 100%;
  padding: 1.5rem;
}
.blockHeaderSmall p {
  margin-top: auto;
  margin-bottom: auto;
  font-family: "Fredoka One", Helvetica, Arial, serif;
  font-weight: 400;
  font-style: normal;
  font-size: 1rem;
  text-align: left;
  vertical-align: middle;
  white-space: nowrap;
  line-height: 0rem;
}

.contentItem {
  margin: 1rem;
  padding-bottom: 1rem;
}

.contentColumn {
  flex: 1 1;
  /* Space between columns */
  margin: 0 8px;

  /* Layout each column */
  display: flex;
  flex-direction: column;
}

.contentColumnFullHeight {
  /* Take available height */
  flex: 1 1;
}

.blockTextLineBold {
  display: inline;
}
.blockTextLineDetails {
  display: inline;
  font-family: "Roboto", Helvetica, Arial, serif;
  color: #c29bff;
}

.blockTextDetailsWhite {
  display: inline;
  font-family: "Roboto", Helvetica, Arial, serif;
  color: #ffffff;
}

.ProjectContainer {
  /* Align sub-items to top */
  align-items: start;
  display: flex;
}

.ProjectImage {
  margin-right: 16px;
  /* Set the width for the media object */
  width: 315px;
}

.ProjectDescription {
  /* Take the remaining width */
  flex: 1 1;
}

.pageHeader {
  grid-column: span 3;
  background-color: rgba(75, 34, 141, 1);
  border-radius: 10rem;
  padding: 0;
  padding-right: 1rem;
  display: grid;
  grid-template-columns: auto;
}
.headerLinkGroupText {
  top: 4px;
  height: auto;
  width: auto;
  font-family: "Fredoka One", Helvetica, Arial, serif;
  font-weight: 400;
  font-style: normal;
  font-size: 34px;
  text-align: right;
}
.logoTextGroup {
  font-family: "Fredoka One", Helvetica, Arial, serif;
  font-weight: 400;
  font-style: normal;

  color: #ffffff;

  text-align: center;
}
.logoTextMcCurdy {
  font-size: 58px;
}
.logoTextJeremy {
  font-size: 73px;
}

.navList {
  grid-column: span 2;
  /* Content is centered horizontally */
  align-items: center;
  display: flex;

  /* Reset styles */
  list-style: none;
  margin: 0;
}
.navListLeftBottom {
  margin-top: auto;
}
.navListRight {
  /* Sticks to the right */
  margin-left: auto;
}

.siteTitleLink {
  font-family: "Fredoka One", Helvetica, Arial, serif;
  font-weight: 400;
  font-style: normal;
  font-size: 73px;
}

nav {
  width: 100%;
  padding-left: 2rem;
  padding-right: 0.5rem;
  display: grid;
  justify-items: left;
  text-align: left;
  grid-template-rows: none;
  grid-template-columns: 0fr 1fr 0.5fr 1fr;
}
.navLinks {
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;

  width: 100%;
  height: 100%;
}
.navLink {
  /*margin-bottom: 0;
  margin-top: auto;*/
}
.navSpacer {
  width: 100%;
  height: 100%;
}
.externalLinks {
  display: flex;
  flex-direction: row;
  justify-content: right;
  align-items: center;
  width: 100%;
  height: 100%;
}
#pageLogo {
  margin-top: auto;
  margin-bottom: auto;
  background-size: cover;
  background-image: url(/img/PortfolioLogo.png);

  width: 286px;
  height: 146px;
  margin-right: 5px;
}
#ProjectsLink {
  background-size: cover;
  background-image: url(/img/ProjectLink_Large.png);
  width: 302px;
  height: 95px;
  margin-right: 5px;
}

#ResumeLink {
  background-size: cover;
  background-image: url(/img/ResumeLink_Large.png);
  width: 249px;
  height: 95px;
  margin-right: 5px;
}

#AboutLink {
  background-size: cover;
  background-image: url(/img/AboutLink_Large.png);
  width: 229px;
  height: 95px;
  margin-right: 5px;
}

.externalLinks img {
  margin-left: 5px;
}

/*
@media (min-width: 30rem) {
  nav {
    grid-template-rows: none;
    grid-template-columns: 1fr auto auto;
    text-align: right;
    justify-items: start;
    border-bottom: 2px solid #cfcfcf;
  }

  hr {
    display: none;
  }
}*/
/*@media (max-width: 82em) {
  nav {
    grid-template-columns: 0fr 0fr 1fr;
    grid-template-rows: 0fr 0fr 0fr 0fr 0fr 0fr;
  }
  .pageLogo {
    grid-row-start: 1;
    grid-row-end: 5;
  }
  .navLink {
    transform: scale(0.75);
  }
  .externalLinks {
    flex-direction: column;
    transform: scale(0.75);
  }
  nav {
    grid-template-columns: 0fr 0fr;
    grid-template-rows: 0fr 0fr 0fr;
  }
  .pageLogo {
    grid-row-start: 1;
    grid-row-end: 2;
  }
  .externalLinks {
    grid-row-start: 2;
    grid-row-end: 3;
  }
  .navLink {
    transform: scale(0.75);
  }
}*/
@media (max-width: 85em) {
  nav {
    grid-template-columns: 0.4fr 0fr 0.4fr;
    grid-template-rows: 0fr 0fr 0fr 0fr 0fr 0fr;
  }
  .pageLogo {
    grid-row-start: 1;
    grid-row-end: 5;
  }
  .externalLinks {
    grid-column-start: 4;
    grid-row-start: 1;
    grid-row-end: 5;
  }
  #ProjectsLink {
    width: 227px;
    height: 72px;
  }

  #ResumeLink {
    width: 187px;
    height: 72px;
  }

  #AboutLink {
    width: 172px;
    height: 72px;
  }
  .externalLinks img {
    width: 75px;
    height: 75px;
  }
  .navLink {
    grid-column-start: 2;
  }
  .navSpacer {
    grid-column-start: 3;
  }
}
@media (max-width: 69em) {
  nav {
    grid-template-columns: 0.4fr 0fr 0.4fr;
    grid-template-rows: 0fr 0fr 0fr 0fr 0fr 0fr;
  }
  .pageLogo {
    grid-row-start: 1;
    grid-row-end: 5;
  }
  .externalLinks {
    grid-column-start: 4;
    grid-row-start: 1;
    grid-row-end: 5;
  }

  .navLink {
    grid-column-start: 2;
    margin-right: auto;
    margin-left: 0px;
  }

  #ProjectsLink {
    width: 166px;
    height: 52px;
    margin-right: auto;
    margin-left: 0px;
    margin-top: 2px;
    margin-bottom: 2px;
  }

  #ResumeLink {
    width: 136px;
    height: 52px;
    margin-right: auto;
    margin-left: 0px;
    margin-top: 2px;
    margin-bottom: 2px;
  }

  #AboutLink {
    width: 125px;
    height: 52px;
    margin-right: auto;
    margin-left: 0px;
    margin-top: 2px;
    margin-bottom: 2px;
  }
  .navLinks {
    display: flex;
    flex-direction: column;
    justify-content: left;
    align-items: left;

    width: 100%;
    height: 100%;
  }
  .externalLinks img {
    width: 75px;
    height: 75px;
  }
  .navSpacer {
    grid-column-start: 3;
  }
}

@media (max-width: 43em) {
  nav {
    grid-template-columns: 1fr;
    grid-template-rows: 0.5fr 0.25fr 0fr 0.25fr;
  }
  .pageLogo {
    transform: scale(0.75);
    grid-row-start: 1;
    grid-row-end: 2;
  }
  #pageLogo {
    margin-top: auto;
    margin-bottom: auto;
    background-size: cover;
    background-image: url(/img/PortfolioLogo.png);
  
    width: 286px;
    height: 146px;
    margin-right: auto;
    margin-left: auto;
  }
  #ProjectsLink {
    background-size: cover;
    background-image: url(/img/ProjectLink_Small.png);
    width: 75px;
    height: 75px;
  }
  
  #ResumeLink {
    background-size: cover;
    background-image: url(/img/ResumeLink_Small.png);
    width: 75px;
    height: 75px;

  }
  
  #AboutLink {
    background-size: cover;
    background-image: url(/img/AboutLink_Small.png);
    width: 75px;
    height: 75px;
  }
  .navLinks {
    width: 286px;
    grid-row-start: 2;
    grid-row-end: 3;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: normal;
    margin-right: auto;
    margin-left: auto;
    margin-bottom: 0em;
  }

  .externalLinks {
    grid-column-start: 1;
    grid-row-start: 4;
    grid-row-end: 5;
    width: 286px;
    align-items: normal;
    margin-right: auto;
    margin-left: auto;
    flex-direction: row;
    justify-content: center;
  }

  .externalLinks img {
    width: 55px;
    height: 55px;
    margin-left: auto;
    margin-right: auto;
  }
  

  .navSpacer {
    grid-row-start: 3;
    grid-row-end: 4;
  }
}

/*43em (688px), 62em (992px), and 82em (1312px)*/

.overlay {
  position: fixed;
  display: block;
  overflow: auto;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(44, 20, 81, 0.75);
  z-index: 999;
  cursor: pointer;
}

.content {
  margin: auto;
  background-color: #6732bb;
  border-radius: 1rem;
  width: 75vw;
  height: 75vh;
  padding: 2rem;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.closeButton {
  background-color: #2c1451;
  border-radius: 5rem;
  width: 2.2rem;
  height: 2rem;
  color: #ffffff;
  padding: 10px;
  cursor: pointer;
  border: 0;
  position: absolute;
  top: 0.3rem;
  right: 0.5rem;
  font-size: 1.5rem;
  line-height: 0.75rem;
}

.verticalLineParent{
     /* Used to position the left vertical line */
     position: relative;
}

.verticalLine{
    z-index: 0;
    /* Border */
    border-right: 1rem solid rgb(255, 255, 255);
    border-radius: 9999px;
    /* Positioned at the left */
    left: -1rem;
    position: absolute;
    top: 1.55rem;

    /* Take full height */
    height: 100%;
}

.timelineContainer{
    /* Reset styles */
    list-style-type: none;
    margin: 0px;
    padding: 0px;
}






.timelineCircle{
    z-index: 1;
    /* Rounded border */
    background-color: rgb(255, 255, 255);
    border-radius: 9999px;

    /* Size */
    height: 3.5rem;
    width:  3.5rem;
    margin-left: -1em;

}

.timelineLineItem{
    margin-bottom: 0px;
}

.timelineItemHeader{
    /* Center the content horizontally */
    align-items: center;
    display: flex;
}

.timelineCircle p{
    margin-top: auto;
  margin-bottom: auto;
  font-family: "Fredoka One", Helvetica, Arial, serif;
  font-weight: 400;
  font-style: normal;
  font-size: 1.25rem;
  text-align: center;
  vertical-align: middle;
  white-space: nowrap;
  line-height: 3.5rem;
  color:#4b228d;
}

.timelineItemTitle{
    /* Take available width */
    flex: 1 1;
    margin-left: 1em;
}
.timelineItemDescription{
    /* Make it align with the title */
    margin-left: 2.5em;
    margin-top: 0.5em;
    margin-bottom: 0.5em;
}



.blockHeaderSmall{
    margin-left: -3rem;
    padding-left: 3.5rem;
    padding-bottom: 2rem;
    margin-top: 1rem;
    margin-bottom: 0.5rem;
    
}

.blockHeaderSmall .timelineItemTitleText{
    font-size: 1.45rem;
    margin-top: -1rem;
    line-height: 1rem;
}

.blockHeaderSmall .timelineItemTitleCompany{
    font-family: "Roboto", Helvetica, Arial, serif;
    font-size: 1rem;
    margin-top: 0.25rem;
}

.timelineCircle .timelineMonth{
    font-family: "Roboto", Helvetica, Arial, serif;
    font-size: 0.75rem;
    margin-top: 1rem;
    margin-bottom: -0.75rem;
    line-height: 0;
}
