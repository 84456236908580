.overlay {
  position: fixed;
  display: block;
  overflow: auto;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(44, 20, 81, 0.75);
  z-index: 999;
  cursor: pointer;
}

.content {
  margin: auto;
  background-color: #6732bb;
  border-radius: 1rem;
  width: 75vw;
  height: 75vh;
  padding: 2rem;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.closeButton {
  background-color: #2c1451;
  border-radius: 5rem;
  width: 2.2rem;
  height: 2rem;
  color: #ffffff;
  padding: 10px;
  cursor: pointer;
  border: 0;
  position: absolute;
  top: 0.3rem;
  right: 0.5rem;
  font-size: 1.5rem;
  line-height: 0.75rem;
}
